import { Component, Inject, Input, OnInit } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';
import { HtmlSnackbarData } from './html-snackbar.model';

@Component({
    selector: 'cs-html-snackbar',
    templateUrl: './html-snackbar.component.html',
    styleUrls: ['./html-snackbar.component.scss'],
})
export class HtmlSnackbarComponent implements OnInit {

    constructor(
        @Inject(MAT_SNACK_BAR_DATA) public snackData: HtmlSnackbarData,
        private snackBarRef: MatSnackBarRef<HtmlSnackbarComponent>
    ) {}

    ngOnInit(): void {}

    close() {
        this.snackBarRef.dismiss();
    }

    colorForAction(action: string) {
        switch(action.toLocaleLowerCase()) {
            case 'error':
                return 'error';
            case 'warn':
                return 'warn';
            case 'ok':
                return 'primary';
            default:
                return '';
        }
    }
}
