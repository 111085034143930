import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { RestService } from '../../../../shared/services/rest.service';
import { DownloadService } from '../../../../shared/services/download.service';
import { LoggerService } from '../../../../shared/services/logger.service';
import { ReportDocumentField } from '../../../state/report.model';
import { DocumentLink } from '../../../../../elements/documentLink/document-link.model';

@Component({
    selector: 'cs-document-link-field',
    templateUrl: './document-link-field.component.html',
    styleUrls: ['./document-link-field.component.scss'],
})
export class DocumentLinkFieldComponent {
    @Input() rowData: any;
    @Input() fieldDefinition: ReportDocumentField;
    @Input() asLink = true;

    constructor(private logger: LoggerService, private rest: RestService, private download: DownloadService) {}

    getFile() {
        const path = this.rowData[this.fieldDefinition.documentPath];
        this.rest
            .apiGet<DocumentLink>('document', { path })
            .pipe()
            .subscribe((data) => {
                const blob = this.download.createBlobFromDataURL(data.dataUrl);
                this.download.downloadFile(blob, data.filename);
            });
    }
}
