import { NgModule } from '@angular/core';
import { BufferModule } from './buffer/buffer.module';
import { DigitizePointModule } from './digitize-point/digitize-point.module';
import { MeasureModule } from './measure/measure.module';
import { PolygonSelectModule } from './polygon-select/polygon-select.module';
import { PrintModule } from './print/print.module';
import { SearchModule } from '../../search/search.module';
import { SharedModule } from '../../shared/shared.module';
import { ZoomLatLonModule } from './zoom-lat-lon/zoom-lat-lon.module';

import { COPY_LAT_LON_COMMAND } from './copy-lat-lon/copy-lat-lon.token';
import { DATA_REPORT_COMMAND } from './data-report/data-report.token';
import { DESELECT_COMMAND } from './deselect/deselect.token';
import { LAUNCH_GOOGLE_MAPS_COMMAND } from './launch-google-maps/launch-google-maps.token';
import { LAUNCH_URL_COMMAND } from './launch-url/launch-url.token';
import { PAN_COMMAND } from './pan/pan.token';
import { RELOAD_COMMAND } from './reload/reload.token';
import { SEARCH_COMMAND } from './search/search.token';
import { SELECT_COMMAND } from './select/select.token';
import { ZOOM_EXTENTS_COMMAND } from './zoom-extents/zoom-extents.token';
import { ZOOM_IN_COMMAND } from './zoom-in/zoom-in.token';
import { ZOOM_OUT_COMMAND } from './zoom-out/zoom-out.token';

import { copyLatLonFactory } from './copy-lat-lon/copy-lat-lon.factory';
import { dataReportFactory } from './data-report/data-report.factory';
import { deselectFactory } from './deselect/deselect.factory';
import { launchGoogleMapsFactory } from './launch-google-maps/launch-google-maps.factory';
import { launchUrlFactory } from './launch-url/launch-url.factory';
import { panFactory } from './pan/pan.factory';
import { reloadFactory } from './reload/reload.factory';
import { searchFactory } from './search/search.factory';
import { selectFactory } from './select/select.factory';
import { zoomExtentsFactory } from './zoom-extents/zoom-extents.factory';
import { zoomInFactory } from './zoom-in/zoom-in.factory';
import { zoomOutFactory } from './zoom-out/zoom-out.factory';
import { MarkupModule } from './markup/markup.module';
import { LOGOUT_COMMAND } from './logout/logout.token';
import { logoutFactory } from './logout/logout.factory';
import { LiveSearchModule } from './live-search/live-search.module';


@NgModule({
    imports: [
        BufferModule,
        DigitizePointModule,
        LiveSearchModule,
        MarkupModule,
        MeasureModule,
        PolygonSelectModule,
        PrintModule,
        SearchModule,
        ZoomLatLonModule,
        SharedModule
    ],
    providers: [
        { provide: COPY_LAT_LON_COMMAND, useFactory: copyLatLonFactory },
        { provide: SELECT_COMMAND, useFactory: selectFactory },
        { provide: DESELECT_COMMAND, useFactory: deselectFactory },
        { provide: PAN_COMMAND, useFactory: panFactory },
        { provide: RELOAD_COMMAND, useFactory: reloadFactory },
        { provide: ZOOM_IN_COMMAND, useFactory: zoomInFactory },
        { provide: ZOOM_OUT_COMMAND, useFactory: zoomOutFactory },
        { provide: ZOOM_EXTENTS_COMMAND, useFactory: zoomExtentsFactory },
        { provide: SEARCH_COMMAND, useFactory: searchFactory },
        { provide: DATA_REPORT_COMMAND, useFactory: dataReportFactory },
        { provide: LAUNCH_URL_COMMAND, useFactory: launchUrlFactory },
        { provide: LAUNCH_GOOGLE_MAPS_COMMAND, useFactory: launchGoogleMapsFactory },
        { provide: LOGOUT_COMMAND, useFactory: logoutFactory },
    ]
})
export class MapCommandModule {}
